import axios from "axios";

const global = require("../config/config");

async function InsertUpload(URL, infoJson) {
  try {
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/dados/InsertUploads/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: {
        token: process.env.REACT_APP_TOKEN_API,
        "Content-Type": "application/json",
      },
      data: {
        url: URL,
      },
    };

    await axios
      .request(options)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  } catch (error) {
    console.error("error", error);
  }
}

async function InsertLog(Cod, Log, infoJson) {
  try {
    var options = {
      method: "POST",
      url: `${global.ApiLink}/v1/${
        infoJson.Stand.Easymanager ? "easymanager" : "easydata"
      }/dados/InsertLog/`,
      params: { dealer_id: infoJson.Stand.Anunciante },
      headers: {
        token: process.env.REACT_APP_TOKEN_API,
        "Content-Type": "application/json",
      },
      data: {
        codigo: Cod,
        log: Log,
      },
    };

    return await axios
      .request(options)
      .then((error) => {
        return error;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  } catch (error) {
    console.error("error", error);
    return error;
  }
}
export { InsertUpload, InsertLog };
