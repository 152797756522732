import React from "react";
import TopNav from "./header/TopNav";
import Footer from "./footer/Footer";
import MessengerCustomerChat from "react-messenger-customer-chat";
import useMediaQuery from "../../components/hooks/useMediaQuery ";
import Dot from "../../components/tools/dot/Dot";
import { Outlet } from "react-router-dom";

export default function Layout({
  infoJson,
  infoPrincipal,
  allinfoText,
  allinfo,
  pontosVenda,
}) {
  const isDesktop = useMediaQuery("(min-width: 992px)");

  return (
    <>
      <TopNav
        allinfo={allinfo}
        infoPrincipal={infoPrincipal}
        infoJson={infoJson}
        allinfoText={allinfoText}
      />
      <Outlet />
      <Footer
        allinfo={pontosVenda}
        infoPrincipal={infoPrincipal}
        infoJson={infoJson}
        allinfoText={allinfoText}
      />
      {infoJson.Stand.PluginFacebok.existe && (
        <MessengerCustomerChat
          pageId={infoJson.Stand.PluginFacebok.PageIdFacebook}
          appId={infoJson.Stand.PluginFacebok.AppIdFacebook}
          language="pt_PT"
        />
      )}
      {isDesktop &&
        infoJson.Layout.ContactarDot &&
        infoPrincipal.length !== 0 && (
          <Dot allinfo={infoPrincipal[0]} infoJson={infoJson} />
        )}
    </>
  );
}
