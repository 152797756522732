import React, { useState } from "react";
import { renderEmail } from "react-html-email";
import * as apiEmail from "../../../../api/apiEmail";
import * as apiCarros from "../../../../api/apiCarros";
import * as apiDados from "../../../../api/apiDados";
import useScriptRecaptcha from "../../../hooks/useScriptRecaptcha";
import { Formik } from "formik";
import ValuesSchema from "./validations/valuesSchema";
import initialValues from "./validations/initialValues";

import { useNavigate } from "react-router";

import EmailDestinatarioEasybuy from "./EmailDestinatarioEasybuy";
import EmailRemetenteEasybuy from "./EmailRemetenteEasybuy";
import FormEasyBuy from "./Form/FormEasyBuy";

export default function FormikEasyBuy({ allinfo, infoJson, allinfoText }) {
  const history = useNavigate();

  const [camposEmail, setCamposEmail] = useState({
    assunto: `Pedido de Avaliação de Veiculo - ${infoJson.Stand.Nome}`,
    user: `${process.env.REACT_APP_EMAIL_USERNAME}`,
    pass: `${process.env.REACT_APP_EMAIL_PASSWORD}`,
    emailCliente: "",
    corpoCliente: "",
    emailEmpresa: "pedro@sobarroso.pt;paulo@sobarroso.pt;pedrosil@gmail.com",
    corpoEmpresa: "",
    nomeEmpresa: `${infoJson.Stand.Nome}`,
    "g-recaptcha-response": "",
    SECRET_KEY: process.env.REACT_APP_RECAPCHA_SECRETKEY,
  });

  useScriptRecaptcha();

  const handleSubmit = (values) => {
    camposEmail["emailCliente"] = values.email;
    let handlerValues = {
      ...values,
      financeiras:
        values.outraFinanceiras !== ""
          ? values.outraFinanceiras
          : values.financeiras,
    };

    setCamposEmail(camposEmail);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPCHA_SITEKEY, { action: "submit" })
        .then((token) => {
          camposEmail["g-recaptcha-response"] = token;
          apiCarros.sendDataEasybuy(handlerValues, infoJson).then((item) => {
            if (item.status === 200) {
              const emailHTMLDestinatario = renderEmail(
                <EmailDestinatarioEasybuy
                  campos={handlerValues}
                  infoJson={infoJson}
                  camposEmail={camposEmail}
                  allinfo={allinfo}
                  data={item.data}
                />
              );
              const emailHTMLRemetente = renderEmail(
                <EmailRemetenteEasybuy
                  campos={handlerValues}
                  infoJson={infoJson}
                  allinfo={allinfo}
                  camposEmail={camposEmail}
                  data={item.data}
                  allinfoText={allinfoText}
                />
              );

              camposEmail["corpoCliente"] = emailHTMLRemetente;
              camposEmail["corpoEmpresa"] = emailHTMLDestinatario;

              apiDados.InsertLog(
                item.data.cod_viatura,
                `Viatura inserida no easyBuy do ${infoJson.Stand.Nome} com sucesso`,
                infoJson
              );

              apiEmail.send2Mail(camposEmail, infoJson).then((error) => {
                if (error.status === 201) {
                  apiDados
                    .InsertLog(
                      item.data.cod_viatura,
                      `Email enviado apartir do easyBuy do ${infoJson.Stand.Nome} com sucesso`,
                      infoJson
                    )
                    .then((res) => {
                      if (res.status === 201) {
                        history("/obrigado/");
                      } else {
                        history("/erro/");
                      }
                    })
                    .catch((item) => console.log("item", item));
                } else {
                  apiDados.InsertLog(
                    item.data.cod_viatura,
                    `Ocorreu um erro ao enviar o email no Easybuy do ${
                      infoJson.Stand.Nome
                    }, o erro foi o seguinte ${JSON.stringify(error)}`,
                    infoJson
                  );
                  history("/erro/");
                }
              });
            } else {
              apiDados.InsertLog(
                item.data.cod_viatura,
                `Ocorreu um erro ao inserir a viatura no Easybuy do ${
                  infoJson.Stand.Nome
                }, o erro foi o seguinte ${JSON.stringify(item)}`,
                infoJson
              );
              history("/erro/");
            }
          });
        });
    });
  };

  return (
    <>
      <Formik
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={ValuesSchema}
      >
        {({
          handleSubmit,
          touched,
          getFieldProps,
          setFieldValue,
          values,
          errors,
        }) => {
          return (
            <>
              <FormEasyBuy
                handleSubmit={handleSubmit}
                touched={touched}
                getFieldProps={getFieldProps}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                infoJson={infoJson}
                allinfoText={allinfoText}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
