function createMetaTags(
  Nome,
  printImg,
  tags,
  description,
  Morada,
  Website,
  Title
) {
  try {
    let print;

    // verifica se a função traz algum link de imagem (Ficha de viatura , ficha de noticias ,...)
    // Se não coloca o link por defeito de uma imagem que se encontra na pasta public/assets/img/partilha_fb.jpg

    printImg !== ""
      ? (print = printImg)
      : (print = Website + "/assets/img/partilha_fb.jpg");

    // inicia a criaçao do objecto que cria as metatags para varias plataformas . Facebook,Twitter
    //? Mais info ver  https://github.com/teemukoivisto/react-seo-meta-tags

    const metaTag = {
      fullWebsiteData: {
        url: window.location.href,
        title: Title,
        description: description,
        language: "pt-PT",
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
        author: {
          email: "suporte@easysite.pt",
          name: "PDC digital",
          image:
            "https://www.easysite.pt/web1/zp/tpl1/template/img/cortes/fav.png",
        },
      },
      blogPostData: {
        url: window.location.href,
        title: Title,
        description: description,
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
        tags: tags,
        author: {
          name: "PDC digital",
          email: "suporte@easysite.pt",
          url: "https://www.easysite.pt/",
          image:
            "https://www.easysite.pt/web1/zp/tpl1/template/img/cortes/fav.png",
        },
      },
      facebookData: {
        title: Title,
        description: description,
        language: "pt-PT",
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
      },
      twitterData: {
        title: Title,
        description: description,
        image: print,
        imageAlt: `LogoTipo ${Nome}`,
        cardType: "summary_large_image",
      },
      organization: {
        name: Nome,
        legalName: Nome,
        description: `Consulte o stock de carros usados para venda no ${Nome} localizado em, ${Morada}. Carros baratos e negócios de ocasião de viaturas multimarcas.`,
        url: Website,
        logo: print,
      },
    };
    return metaTag;
  } catch (error) {
    console.log("error", error);
  }
}



const isElementInViewport = (el) => {
  try {
    if (el) {
      var rect = el.getBoundingClientRect();
      var viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }
  } catch (error) {
    console.log("error", error);
  }
};

export {
  createMetaTags,
  isElementInViewport,
};
