import React, { useState } from "react";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import EasybuyCores from "./2Parte/EasybuyCores";
import EasybuyHistorico from "./2Parte/EasybuyHistorico";
import { EasybuyMetalizado } from "./2Parte/EasybuyMetalizado";
import EasybuyPneus from "./2Parte/EasybuyPneus";
import ast from "../../../../../assets/icons/easybuy/ast.svg";
import { ReactComponent as Mais } from "../../../../../assets/icons/mais.svg";

import css from "./formulario.module.css";
import EasybuyDanos from "./2Parte/EasybuyDanos";
import Spacer from "../../Spacer";
import EasybuyExtras from "./2Parte/EasybuyExtras";
import { getIn } from "formik";

export default function SegundaParte({
  touched,
  getFieldProps,
  setFieldValue,
  values,
  errors,
  infoJson,
  Extras,
}) {
  const [showExtras, setShowExtras] = useState(false);
  const [showDanos, setShowDanos] = useState(false);

  return (
    <>
      {/* <Col xs={12}>
        <Col
          xs={12}
          className={`${css.boxShadowPares} boxShadowPares ${
            values.cor.id !== "" && values.metalizado !== "" ? "active" : ""
          } ${
            Boolean(getIn(errors, "cor.id")) &&
            Boolean(getIn(touched, "cor.id"))
              ? "inactive"
              : ""
          } mb-4`}
        >
          <label>
            <img src={ast} alt="asterisco" /> Selecione a Cor
          </label>
          <Spacer height="15px" />

          <EasybuyCores
            setFieldValue={setFieldValue}
            values={values}
            infoJson={infoJson}
          />

          <EasybuyMetalizado setFieldValue={setFieldValue} values={values} />
          <Spacer height="15px" />
        </Col>
      </Col> */}
      {/* <Col xs={12}>
        <Col
          xs={12}
          className={`${css.boxShadowPares} boxShadowPares ${
            values.extras.length > 0 ? "active" : ""
          } mb-4`}
        >
          <label>
            Extras
            {values.extras.length !== 0 && <span>{values.extras.length}</span>}
          </label>

          {showExtras ? (
            <>
              <Spacer height="15px" />
              <EasybuyExtras
                setFieldValue={setFieldValue}
                values={values}
                infoJson={infoJson}
                Extras={Extras}
              />
              <Spacer height="15px" />
            </>
          ) : (
            <>
              <div
                onClick={() => setShowExtras(true)}
                className={`btnPesquisa ${css.btnMore}`}
              >
                Preecher Extras{" "}
                <Mais style={{ marginLeft: "10px", stroke: "#ffffff" }} />
              </div>
            </>
          )}
        </Col>
      </Col> */}
      <Col xs={12}>
        <Col
          xs={12}
          className={`${css.boxShadowPares} boxShadowPares ${
            values.historico.primeiroDono !== "" &&
            values.historico.livroRevisao !== "" &&
            values.historico.fumador !== "" &&
            values.historico.importado !== "" &&
            values.historico.acidentes !== "" &&
            values.historico.segundaChave !== ""
              ? "active"
              : ""
          } ${
            (Boolean(getIn(errors, "historico.primeiroDono")) &&
              Boolean(getIn(touched, "historico.primeiroDono"))) ||
            (Boolean(getIn(errors, "historico.livroRevisao")) &&
              Boolean(getIn(touched, "historico.livroRevisao"))) ||
            (Boolean(getIn(errors, "historico.livroRevisao")) &&
              Boolean(getIn(touched, "historico.livroRevisao"))) ||
            (Boolean(getIn(errors, "historico.fumador")) &&
              Boolean(getIn(touched, "historico.fumador"))) ||
            (Boolean(getIn(errors, "historico.importado")) &&
              Boolean(getIn(touched, "historico.importado"))) ||
            (Boolean(getIn(errors, "historico.acidentes")) &&
              Boolean(getIn(touched, "historico.acidentes"))) ||
            (Boolean(getIn(errors, "historico.segundaChave")) &&
              Boolean(getIn(touched, "historico.segundaChave")))
              ? "inactive"
              : ""
          } mb-4`}
        >
          <label>
            <img src={ast} alt="asterisco" /> Histórico
          </label>
          <Spacer height="15px" />
          <EasybuyHistorico setFieldValue={setFieldValue} values={values} />
          <Spacer height="15px" />
        </Col>
      </Col>
      {/* <Col xs={12}>
        <Col
          xs={12}
          className={`${css.boxShadowPares} boxShadowPares ${
            values.danos.length !== 0 ? "active" : ""
          }  mb-4`}
        >
          <label>Danos</label>

          {showDanos ? (
            <>
              <Spacer height="15px" />
              <EasybuyDanos setFieldValue={setFieldValue} values={values} />

              <FloatingLabel
                label={
                  <>Assinalou danos na viatura? Descreva aqui esses danos</>
                }
                controlId="formGridQuilometros"
              >
                <Form.Control
                  type="text"
                  placeholder="Exemplo: Friso da optica danificado"
                  {...getFieldProps("textDanos")}
                  isValid={values.textDanos !== ""}
                  isInvalid={!!errors.textDanos && touched.textDanos}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.textDanos}
                </Form.Control.Feedback>
              </FloatingLabel>
              <Spacer height="15px" />
            </>
          ) : (
            <>
              <div
                onClick={() => setShowDanos(true)}
                className={`btnPesquisa ${css.btnMore}`}
              >
                Preecher danos{" "}
                <Mais style={{ marginLeft: "10px", stroke: "#ffffff" }} />
              </div>
            </>
          )}
        </Col>
      </Col> */}
      <Col xs={12}>
        <Col
          xs={12}
          className={`${css.boxShadowPares} boxShadowPares ${
            values.pneus.dianteiros !== "" && values.pneus.traseiros !== ""
              ? "active"
              : ""
          } ${
            (Boolean(getIn(errors, "pneus.dianteiros")) &&
              Boolean(getIn(touched, "pneus.dianteiros"))) ||
            (Boolean(getIn(errors, "pneus.traseiros")) &&
              Boolean(getIn(touched, "pneus.traseiros")))
              ? "inactive"
              : ""
          } mb-4`}
        >
          <label>
            <img src={ast} alt="asterisco" /> Estado dos Pneus
          </label>
          <Spacer height="15px" />
          <EasybuyPneus setFieldValue={setFieldValue} values={values} />
          <Spacer height="15px" />
        </Col>
      </Col>
    </>
  );
}
