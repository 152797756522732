import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Spacer from "../home/Spacer";
import * as apiJson from "../../../api/apiJson";
import { createMetaTags } from "../../helpers/tratarInfo";
import css from "../home/home.module.css";

export default function Funciona({ infoJson, setMetaTags, allinfoText }) {
  const [alltexto, setAlltexto] = useState({
    Titulo: "",
    SubTitulo: "",
    Zona: "",
    Url: "",
  });

  useEffect(() => {
    allinfoText.ComoFunciona && setAlltexto(allinfoText.ComoFunciona);
  }, [allinfoText]);

  useEffect(() => {
    let Morada;
    if (infoJson.Stand.Distrito === infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Distrito;
    }
    if (infoJson.Stand.Distrito !== infoJson.Stand.Concelho) {
      Morada = infoJson.Stand.Concelho + ", " + infoJson.Stand.Distrito;
    }
    const description =
      `A informação sobre a viatura que pretende vender e submete para avaliação.` +
      `  Visite-nos em ${infoJson.Stand.Concelho}`;

    const tags = [
      "Como Funciona",
      "Pressupostos de Avaliação",
      "Inspeção antes de entrega",
      "Finalizar a Venda",
      "Compramos a sua viatura",
      "compramos",
      "Carros",
      "Venda",
      "Hora",
      "Dinheiro",
      "Oferta",
      "Funcionamento",
    ];

    const title = `${
      alltexto.Titulo !== "" ? alltexto.Titulo : infoJson.ComoFunciona.titulo
    } - ${infoJson.Stand.Nome} em ${infoJson.Stand.Concelho}`;

    const metatags = createMetaTags(
      infoJson.Stand.Nome,
      "",
      tags,
      description,
      Morada,
      infoJson.Stand.Website,
      title
    );
    apiJson.createMetaTag({
      url:
        allinfoText.ComoFunciona && allinfoText.ComoFunciona.Url !== ""
          ? allinfoText.ComoFunciona.Url
          : infoJson.ComoFunciona.url,
      title: title,
      description: description,
      thumbnail: infoJson.Stand.Website + "/assets/img/partilha_fb.jpg",
    });
    setMetaTags(metatags);
  }, [infoJson, setMetaTags, alltexto, allinfoText.ComoFunciona]);

  return (
    <>
      <section>
        <Spacer height="90px" />
        <Spacer height="40px" clasName="col-12" />
        <Container>
          <Row>
            <h1 xs={12} className={css.listagem_titulo}>
              {alltexto.Titulo !== ""
                ? alltexto.Titulo
                : infoJson.ComoFunciona.titulo}
            </h1>

            {alltexto.SubTitulo && (
              <h2 xs={12} className={`${css.listagem_subtitulo} titulo-page`}>
                {alltexto.SubTitulo}
              </h2>
            )}
            <Spacer height="15px" clasName="col-12" />
            <Spacer height="20px" clasName="col-12" />

            {alltexto.Zona ? (
              <p
                xs={12}
                className={`${css.paragrafo}`}
                dangerouslySetInnerHTML={{ __html: alltexto.Zona }}
              />
            ) : (
              <>
                <p align="justify">
                  <font size="3">
                    <strong>Como Funciona:</strong>
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    No Site introduza toda a informação sobre a viatura que
                    pretende vender e submeta para avaliação.<br></br>É
                    importante que a informação seja o mais precisa possível
                    quanto ao estado da sua viatura, pois é com base na
                    informação que nos presta que vamos atribuir um valor.
                    <br></br>
                    Num prazo inferior a 48 horas irá receber a nossa oferta
                    (válida por um período de 7 dias).<br></br>
                    Deve agendar uma deslocação às nossas instalações, em Braga,
                    para finalizar o processo. <br></br>
                  </font>{" "}
                  <font size="3">
                    <strong>Pressupostos de Avaliação:</strong>
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    Para além da informação que nos presta, através da
                    introdução dos dados do seu carro, a nossa avaliação tem em
                    consideração os seguintes pressupostos:
                  </font>
                  <font size="3">
                    <br></br>- Ter os impostos em dia.<br></br>- Ter IPO válida.
                    <br></br>- Ter matrícula portuguesa.<br></br>- Nunca ter
                    sido considerado Perda Total, num sinistro.<br></br>- Nunca
                    ter sido utilizado como Táxi, Rent-a-car, Escola de Condução
                    ou Competição.<br></br>- Ter a quilometragem real.<br></br>-
                    Ter Manuais e Livro de Revisões.<br></br>- Ter duplicado de
                    chaves<br></br>- Ser proprietário da viatura*.<br></br>- Não
                    estar penhorada.
                  </font>
                  <font size="3">
                    <br></br>
                    Se tem dúvidas ou acha que a sua viatura pode não estar
                    conforme estes pressupostos, faça a devida menção quando
                    estiver a introduzir os dados da mesma, para avaliação.
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    <br></br>
                    *Se a viatura estiver financiada, também existe
                    disponibilidade para fazermos a compra. Neste caso será
                    pedido o valor de liquidação à Financeira e gerimos todo o
                    processo, mediante autorização sua. <br></br>O pagamento da
                    viatura será efectuado por encontro de contas.
                  </font>{" "}
                  <br></br>
                  <br></br>
                  <font size="3"></font>{" "}
                  <font size="3">
                    <strong>Inspeção antes de entrega:</strong>
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    Tendo consciência que os clientes não são especialistas a
                    avaliar danos e as condições em que se encontra a sua
                    viatura, e acreditando na sinceridade da descrição que fez
                    da mesma quando a submeteu para avaliação, reservamo-nos o
                    direito de fazer uma inspeção à viatura para decidirmos se
                    queremos, ou não, adquirir a mesma e qual o valor definitivo
                    de compra.<br></br>
                    <br></br>
                  </font>{" "}
                  <font size="3"></font>{" "}
                  <font size="3">
                    <strong>Finalizar a Venda:</strong>
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    Após a inspeção da viatura e o acordo final quanto ao seu
                    valor, será efetuada a aquisição da viatura.
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    Será obtida uma Certidão de Registo Automóvel via IRN para
                    validar se a viatura se encontra em condições de ser
                    transferida de nome. <br></br>A compra e venda é toda
                    documentada, ficando com cópia de todos os documentos
                    envolvidos. <br></br>O pagamento será feito por
                    transferência bancária, pelo que deverá fazer-se acompanhar
                    pelo seu NIB (Número de Identificação Bancária). O pedido de
                    alteração de registo será submetido. <br></br>
                    <br></br>
                    Obrigado por ter lido as nossas explicações, qualquer dúvida
                    adicional estamos à sua disposição para tentar esclarecer.
                    <br></br>
                  </font>{" "}
                  <font size="3">
                    <br></br>
                    Bom Negócio
                  </font>
                </p>
              </>
            )}
          </Row>
        </Container>
        <Spacer height="150px" clasName="col-12" />
      </section>
    </>
  );
}
