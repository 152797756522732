import React, { useState, useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import css from "./formulario.module.css";
import check_on from "../../../../../assets/icons/easybuy/check_on.svg";

export default function CountRequired({
  values,
  segundaParte,
  setSegundaParte,
}) {
  const [contPrimeiraParte, setContPrimeiraParte] = useState(0);
  const [contSegundaParte, setContSegundaParte] = useState(0);

  useEffect(() => {
    let count = 0;

    if (values.matricula !== "") {
      count = count + 1;
    }
    if (values.mes !== "") {
      count = count + 1;
    }
    if (values.ano !== "") {
      count = count + 1;
    }
    if (values.marca.id !== "") {
      count = count + 1;
    }
    if (values.modelo.id !== "") {
      count = count + 1;
    }
    if (values.combustivel.id !== "") {
      count = count + 1;
    }
    if (values.potencia !== "") {
      count = count + 1;
    }
    if (values.cilindrada !== "") {
      count = count + 1;
    }
    if (values.porta.id !== "") {
      count = count + 1;
    }
    if (values.transmissao.id !== "") {
      count = count + 1;
    }
    if (values.versao.nome !== "") {
      count = count + 1;
    }
    if (values.km !== "") {
      count = count + 1;
    }
    if (values.lugar.id !== "") {
      count = count + 1;
    }

    setContPrimeiraParte(count);

    if (count === 13) {
      setSegundaParte(true);
    }
  }, [values, setSegundaParte]);

  useEffect(() => {
    let count = 0;

    if (values.matricula !== "") {
      count = count + 1;
    }
    if (values.mes !== "") {
      count = count + 1;
    }
    if (values.ano !== "") {
      count = count + 1;
    }
    if (values.marca.id !== "") {
      count = count + 1;
    }
    if (values.modelo.id !== "") {
      count = count + 1;
    }
    if (values.combustivel.id !== "") {
      count = count + 1;
    }
    if (values.potencia !== "") {
      count = count + 1;
    }
    if (values.cilindrada !== "") {
      count = count + 1;
    }
    if (values.porta.id !== "") {
      count = count + 1;
    }
    if (values.transmissao.id !== "") {
      count = count + 1;
    }
    if (values.versao.name !== "") {
      count = count + 1;
    }
    if (values.km !== "") {
      count = count + 1;
    }
    if (values.lugar.id !== "") {
      count = count + 1;
    }
    // if (values.cor.id !== "") {
    //   count = count + 1;
    // }
    if (values.metalizado !== "") {
      count = count + 1;
    }
    if (values.historico.primeiroDono !== "") {
      count = count + 1;
    }
    if (values.historico.livroRevisao !== "") {
      count = count + 1;
    }
    if (values.historico.fumador !== "") {
      count = count + 1;
    }
    if (values.historico.importado !== "") {
      count = count + 1;
    }
    if (values.historico.acidentes !== "") {
      count = count + 1;
    }
    if (values.historico.segundaChave !== "") {
      count = count + 1;
    }
    if (values.pneus.dianteiros !== "") {
      count = count + 1;
    }
    if (values.pneus.traseiros !== "") {
      count = count + 1;
    }
    if (values.nome !== "") {
      count = count + 1;
    }
    if (values.email !== "") {
      count = count + 1;
    }
    if (values.telemovel !== "") {
      count = count + 1;
    }
    if (values.distrito.id !== "") {
      count = count + 1;
    }
    if (values.concelho.id !== "") {
      count = count + 1;
    }
    if (values.valorPretende !== "") {
      count = count + 1;
    }
    if (values.motivoVenda !== "") {
      count = count + 1;
    }
    setContSegundaParte(count);
  }, [values]);

  return (
    <div className={`listagem_info ${css.rectangle}`}>
      <Container>
        <Col>
          <img src={check_on} alt={"check"} style={{ marginRight: "10px" }} />
          {segundaParte ? (
            <>{contSegundaParte} de 29 campos preenchidos com sucesso</>
          ) : (
            <>
              {contPrimeiraParte === 0 ? (
                <>Nenhum campo preenchidos com sucesso</>
              ) : (
                <>{contPrimeiraParte} de 13 campos preenchidos com sucesso</>
              )}
            </>
          )}
        </Col>
      </Container>
    </div>
  );
}
