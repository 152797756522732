import React from "react";
import css from "./menuMobile.module.css";
import { Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as Emp } from "../../../../assets/icons/menu/emp.svg";
import { ReactComponent as Contact } from "../../../../assets/icons/menu/contact.svg";
import { ReactComponent as Inicio } from "../../../../assets/icons/menu/inicio.svg";
import { ReactComponent as Fechar } from "../../../../assets/icons/modal/fechar.svg";
import { ReactComponent as Perg } from "../../../../assets/icons/menu/serv_consult.svg";
import { ReactComponent as Func } from "../../../../assets/icons/menu/funciona.svg";
import { ReactComponent as SvgTestemunhos } from "../../../../assets/icons/servicos/svg_testemunhos.svg";

import MenuFala from "./MenuFala";
import MenuRedes from "./MenuRedes";
import MenuFooter from "./MenuFooter";
import MenuTradutor from "./MenuTradutor";

const logo = "/assets/img/Logo.svg";

export default function MenuMobile({
  allinfo,
  show,
  onHide,
  infoJson,
  allinfoText,
}) {
  return (
    <>
      <Offcanvas show={show} onHide={onHide} placement="start">
        <Offcanvas.Header>
          <Offcanvas.Title className="m-auto">
            <Link to="/" onClick={onHide}>
              <div
                className={`nav navbar-nav navbar-mobile ${css.menu_mobile_stand}`}
              >
                <img src={logo} alt={infoJson.Stand.Nome} />
              </div>
            </Link>
          </Offcanvas.Title>

          <div onClick={() => onHide()} className={`${css.close}  close`}>
            <Fechar />
            Fechar
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="col p-3">
            <ul className={`${css.navbarMobile}  `}>
              <li>
                <Link to="/" onClick={onHide} className={`menu `}>
                  <div className={`${css.boxMenuBottom}  boxMenuMobile`}>
                    <Inicio className=" p-0 mb-2 " height={`27px`} />
                    Início
                  </div>
                </Link>
              </li>
              {infoJson.QuemSomos.existe && (
                <li>
                  <NavLink
                    to={
                      allinfoText.QuemSomos && allinfoText.QuemSomos.Url !== ""
                        ? allinfoText.QuemSomos.Url
                        : infoJson.QuemSomos.url
                    }
                    state={{ noative: true }}
                    className={(navData) =>
                      navData.isActive ? `active menu ` : `menu `
                    }
                    onClick={onHide}
                  >
                    <div className={`${css.boxMenuBottom}  boxMenuMobile`}>
                      <Emp className=" p-0 mb-2 " height={`27px`} />

                      {allinfoText.QuemSomos &&
                      allinfoText.QuemSomos.Titulo !== ""
                        ? allinfoText.QuemSomos.Titulo
                        : infoJson.QuemSomos.titulo}
                    </div>
                  </NavLink>
                </li>
              )}

              {infoJson.PerguntasFrequentes.existe && (
                <li>
                  <NavLink
                    to={
                      allinfoText.PerguntasFrequentes &&
                      allinfoText.PerguntasFrequentes.Url !== ""
                        ? allinfoText.PerguntasFrequentes.Url
                        : infoJson.PerguntasFrequentes.url
                    }
                    state={{ noative: true }}
                    className={(navData) =>
                      navData.isActive ? `active menu ` : `menu `
                    }
                    onClick={onHide}
                  >
                    <div className={`${css.boxMenuBottom}  boxMenuMobile`}>
                      <Perg className=" p-0 mb-2 " height={`27px`} />

                      {allinfoText.PerguntasFrequentes &&
                      allinfoText.PerguntasFrequentes.Titulo !== ""
                        ? allinfoText.PerguntasFrequentes.Titulo
                        : infoJson.PerguntasFrequentes.titulo}
                    </div>
                  </NavLink>
                </li>
              )}

              {infoJson.ComoFunciona.existe && (
                <li>
                  <NavLink
                    to={
                      allinfoText.ComoFunciona &&
                      allinfoText.ComoFunciona.Url !== ""
                        ? allinfoText.ComoFunciona.Url
                        : infoJson.ComoFunciona.url
                    }
                    state={{ noative: true }}
                    className={(navData) =>
                      navData.isActive ? `active menu ` : `menu `
                    }
                    onClick={onHide}
                  >
                    <div className={`${css.boxMenuBottom}  boxMenuMobile`}>
                      <Func className=" p-0 mb-2 " height={`27px`} />

                      {allinfoText.ComoFunciona &&
                      allinfoText.ComoFunciona.Titulo !== ""
                        ? allinfoText.ComoFunciona.Titulo
                        : infoJson.ComoFunciona.titulo}
                    </div>
                  </NavLink>
                </li>
              )}

              {infoJson.Testemunhos.existe && (
                <>
                  <li>
                    <NavLink
                      to={
                        allinfoText.Testemunhos &&
                        allinfoText.Testemunhos.Url !== ""
                          ? allinfoText.Testemunhos.Url
                          : infoJson.Testemunhos.url
                      }
                      state={{ noative: true }}
                      className={(navData) =>
                        navData.isActive ? `active menu ` : `menu `
                      }
                      onClick={onHide}
                    >
                      <div className={`${css.boxMenuBottom} boxMenuMobile`}>
                        <SvgTestemunhos
                          className=" p-0 mb-2 "
                          height={`27px`}
                        />
                        {allinfoText.Testemunhos &&
                        allinfoText.Testemunhos.Titulo !== ""
                          ? allinfoText.Testemunhos.Titulo
                          : infoJson.Testemunhos.titulo}
                      </div>
                    </NavLink>
                  </li>
                </>
              )}
              {infoJson.Contactos.existe && (
                <>
                  <li>
                    <NavLink
                      to={
                        allinfoText.Contactos &&
                        allinfoText.Contactos.Url !== ""
                          ? allinfoText.Contactos.Url
                          : infoJson.Contactos.url
                      }
                      state={{ noative: true }}
                      className={(navData) =>
                        navData.isActive ? `active menu ` : `menu `
                      }
                      onClick={onHide}
                    >
                      <div className={`${css.boxMenuBottom} boxMenuMobile`}>
                        <Contact className=" p-0 mb-2 " />
                        {allinfoText.Contactos &&
                        allinfoText.Contactos.Titulo !== ""
                          ? allinfoText.Contactos.Titulo
                          : infoJson.Contactos.titulo}
                      </div>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div
            className={`${infoJson.Layout.ThemeDark ? css.boxBlack : css.boxt}`}
          ></div>
          <MenuFala allinfo={allinfo} infoJson={infoJson} />
          <MenuRedes allinfo={allinfo} />
          {infoJson.Layout.Tradutor && <MenuTradutor />}
          <MenuFooter
            allinfo={allinfo}
            infoJson={infoJson}
            allinfoText={allinfoText}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
